$pagination-color: $background-color;

.modal-content {
  border-radius: 0px;
  //background-color: $background-color;
}

.modal-footer {
  border-top: 1px solid lighten($background-color, 7);
  .btn-primary, .btn-primary:active, .btn-primary:hover {
    background-color: white;
    color: black;
  }
}

.btn-primary {
  background-color: $background-color;
  border-color: $background-color;
  &:hover {
    background-color: lighten($background-color, 10);
  }
}

.label-success {
  background-color: #b0c500;
}

//Bootstrap
//.btn {
//  border-radius: 2px;
//  border-bottom-width: 3px;
//  padding: 3px 16px;
//  font-size: 13px;
//  text-transform: uppercase;
//}
.btn {
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 13px;
  //text-transform: uppercase;
  //border:none;

}

.progress-sm {
  height: 10px;
}

.progress-xs {
  height: 4px;
}

.modal {
  .panel-body {
    padding: 15px !important;
  }
  .panel {
    box-shadow: none !important;
  }
}

.panel {
  border-radius: 0;
  color: #333;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.2);
  .panel {
    box-shadow: none !important;
    .panel-body {
      padding: 15px !important;

    }
  }
  .panel-title {
    font-weight: bold;
  }
  .panel-body {
    padding: 15px 30px;
    @media screen and (max-width: 480px) {
      padding: 8px !important;
    }
  }
  .panel-heading {
    padding: 15px 30px;
  }
}

.input-group-addon, .form-control, .progress {
  border-radius: 0 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:not(select) {
  border-radius: 0;
  //padding: 10px;
  //height: auto;
}

table .progress {
  margin: 0;
}

.vertical-align, table.vertical-align td {
  vertical-align: middle !important;
}

ul.breadcrumb {
  background: transparent;
  text-align: center;
  a {
    color: white;
  }
  li {
    &.active {
      color: rgba(white, .6)
    }
  }
}

.glyphicon-2x {
  font-size: 2em;
}

//Pagination

.pagination {
  display: inline-block;
  width: 100%;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  text-align: center;
  > {
    li {
      display: inline-block;
      > {
        a, span {
          margin: 0 10px;
          position: relative;
          float: left;
          padding: 6px 12px;
          margin-left: -1px;
          line-height: 1.42857143;
          color: $pagination-color;
          text-decoration: none;
          background-color: #fff;
          border: 1px solid #ddd;
        }
      }
      &:first-child > {
        a, span {
          margin-left: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child > {
        a, span {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      > {
        a:hover, span:hover, a:focus, span:focus {
          z-index: 2;
          color: #23527c;
          background-color: #eee;
          border-color: #ddd;
        }
      }
    }
    .active > {
      a, span, a:hover, span:hover, a:focus, span:focus {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: $pagination-color;
        border-color: $pagination-color;
      }
    }
    .disabled > {
      span {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
        &:hover, &:focus {
          color: #777;
          cursor: not-allowed;
          background-color: #fff;
          border-color: #ddd;
        }
      }
      a {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
        &:hover, &:focus {
          color: #777;
          cursor: not-allowed;
          background-color: #fff;
          border-color: #ddd;
        }
      }
    }
  }
}

.pagination-lg > li {
  > {
    a, span {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
    }
  }
  &:first-child > {
    a, span {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  &:last-child > {
    a, span {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.pagination-sm > li {
  > {
    a, span {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  &:first-child > {
    a, span {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  &:last-child > {
    a, span {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
  li {
    display: inline;
    > {
      a, span {
        display: inline-block;
        padding: 5px 14px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 15px;
      }
      a {
        &:hover, &:focus {
          text-decoration: none;
          background-color: #eee;
        }
      }
    }
  }
  .next > {
    a, span {
      float: right;
    }
  }
  .previous > {
    a, span {
      float: left;
    }
  }
  .disabled > {
    a {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      &:hover, &:focus {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
      }
    }
    span {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
    }
  }
}

.alert {
  background-color: white;
  color: #333;
  border: 1px solid;
  //border-left-width: 8px;
  border-radius: 0;
}

.alert-success {
  background-color: $secondary-color !important;
  border-color: darken($secondary-color, 10);
}

.alert-warning {
  background-color: $warning-color !important;
  border-color: darken($warning-color, 10);
  color: white;
}

.alert-danger {
  background-color: $danger-color !important;
  border: darken($danger-color, 10);
  color: white;
}