$background-color: #497dcc;
$secondary-color: #9fc63b;
$warning-color: #eea33a;
$danger-color: #ee3836;
$optionsDelay: .2s;

$chart-color-a: #DB2B39;
$chart-color-b: #29335C;
$chart-color-c: #F3A712;

%hover {
  &:hover {
    cursor: pointer;
  }
}

.progress-bar-chart- {
  &a {
    background-color: $chart-color-a
  }
  &b {
    background-color: $chart-color-b
  }
  &c {
    background-color: $chart-color-c
  }
}

@import "custombootstrap";
@import "loader";
@keyframes slideInDown {
  from {
    transform: translateY(-30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.printable {
  .hidden-print {
    display: none;
  }
}

body.app.printable {
  background-color: #fff !important;
  .breadcrumb-container,.top-menu {
    display: none;
  }
}
body.app {
  background: $background-color;
  //color: white;
  font-family: 'Raleway', sans-serif;
  &.admin {
    background-color: #ff9800;
  }
  @media print {
    background-color: white;
  }
}

.select2-container * {
  color: #333 !important;
}

.top-menu {
  padding: 20px;
}

.logo {
  max-width: 40px;
  margin: auto;
  display: block;
}

.main-title {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.wrap {
  //padding-top: 1em;
  padding-bottom: 50px;
}

.pin-code {
  padding: 10px 15px;
  border-radius: 30px;
  height: auto;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
}

footer {
  z-index: 8000;
  background: darken($background-color, 30);
  text-transform: uppercase;
  text-align: center;
  padding: .2em;
  font-size: 2em;
  font-weight: bolder;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.question-wrapper {
  padding: 1em 2em .8em;
  color: white;
}

.light-background {
  .question-wrapper {

    .question, .answers {
      color: rgba(black, .5) !important;
    }
  }
}

.question h2 {
  animation: slideInDown 1s ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  font-weight: 700;
  line-height: 1.3;
  strong {
    color: orange;
  }
  &.long-question {
    font-size: 1.6em;

  }
}

.fit-column {
  width: 1%;
  white-space: nowrap;
}

table .btn-group > .btn {
  float: none;
  display: inline-block;
}

table .btn-group {
  white-space: nowrap;
}

$input-size: 24px;
.answers {
  margin-top: 30px;
  .answer {
    opacity: 0;
    margin-top: .8em;
    animation: slideInDown 1s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @for $i from 0 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: $i * $optionsDelay;
      }
    }
  }
  input {
    display: none;

    &:checked + label span {
      background-color: white;
      .light-background & {
        background-color: rgba(black, 0.4)
      }
    }
    + label span {
      height: $input-size;
      width: $input-size;
      display: inline-block;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      margin-right: $input-size;
      vertical-align: middle;
      position: relative;
      background-color: transparent;
      &:after {
        content: "";
        height: $input-size + 8;
        width: $input-size + 8;
        border: 2px solid white;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1000;
        .light-background & {
          border-color: rgba(black, 0.4)
        }

      }

    }
  }
  label {
    @extend %hover;

    font-weight: 700;
    font-size: 1.3em;

  }
}

.progress-test {
  position: fixed;
  z-index: 205;
  bottom: 50px;
  left: 0;
  width: 100%;
  .progress-bar-test {
    width: 0;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 10px;
    display: block;
    height: 2px;
    transition: all .3s ease;
  }
}

.actions {
  position: fixed;
  z-index: 200;
  bottom: 50px;
  width: 100%;
  left: 0;
  padding: 2em;
}

.begin-btn {
  width: 180px;
  height: 180px;
  border: 2px solid white;
  border-radius: 100%;
  padding: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin: auto;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-shadow: 0px 0px 70px 0 rgba(black, .2);
  @extend %hover;
}

.next-btn, .end-btn {
  cursor: pointer;
  &:not(.collapse) {
    border-radius: 30px;
    text-align: center;
    background-color: white;
    font-weight: 700;
    text-transform: uppercase;
    padding: .4em .8em;
    display: block;
  }

}

// Inherited from site.css

html,
body {
  height: 100%;
}

.wrap {
  //min-height: 100%;
  height: auto;
  /*margin: 0 auto -60px;*/
  /*padding: 0 0 60px;*/
}

.wrap > .container {
  /*padding: 70px 15px 20px;*/
}

.not-set {
  color: #c55;
  font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: /*"\e113"*/
          "\e151";
}

a.desc:after {
  content: /*"\e114"*/
          "\e152";
}

.sort-numerical a.asc:after {
  content: "\e153";
}

.sort-numerical a.desc:after {
  content: "\e154";
}

.sort-ordinal a.asc:after {
  content: "\e155";
}

.sort-ordinal a.desc:after {
  content: "\e156";
}

.grid-view th {
  white-space: nowrap;
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
  padding: 15px;
  border: none;
}

@media(max-width: 767px) {
  .nav li > form > button.logout {
    display: block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
  text-decoration: none;
}

.nav > li > form > button.logout:focus {
  outline: none;
}

.home-menu {
  padding: 2em;
  padding-top: 6em;
  li {
    list-style: none;
    a {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.8em;
      font-weight: 700;
      color: white;
      padding: .6em .2em;
      display: block;
      position: relative;
      transition: all .3s ease;
      transform-origin: left;
      opacity: .9;
      &:hover {
        opacity: 1;
        transform: scale(1.2);

      }
    }
    border-bottom: 2px solid #ffffff26;
    &:last-child {
      border-bottom: none;
    }
  }
}

.campaign-chart {
  @media screen and (max-width: 768px) {
    max-width: 200px;
    margin: auto;
  }
}

.special {
  font-weight: bold;
  color: $background-color;
}

.btn-login, .btn-logout, .lang-selector {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 30px;
  color: white;
  font-size: 12px;
  padding: 2px 8px;
}

.lang-selector {
  display: none;
  position: fixed;
  top: 10px;
  //display: inline-block;
  left: 10px;
  width: 80px;
  text-align: center;

}

.results {
  .question {
    min-height: 210px;
  }
}

.score {
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  &.small {
    font-size: 2em;
  }
}

$arrow-width: 20px;
.progress-score {
  overflow: visible;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff004c+0,ff9900+50,a5ff00+100 */
  background: #ff004c; /* Old browsers */
  background: -moz-linear-gradient(left, #ff004c 0%, #ff9900 50%, #a5ff00 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ff004c 0%, #ff9900 50%, #a5ff00 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ff004c 0%, #ff9900 50%, #a5ff00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff004c', endColorstr='#a5ff00', GradientType=1); /* IE6-9 */
  .progress-bar {
    background-color: rgba(black, .0);
    border-right: 1px dotted rgba(black, .5);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: -$arrow-width/2 - 1;
      top: 100%;
      display: block;
      border-width: 0 $arrow-width/2 $arrow-width/2 $arrow-width/2;
      border-color: transparent transparent black transparent;
      border-style: solid;
    }
  }
}

.campaign-completed {
  padding: 30px;
  border-radius: 10px;
  border-color: #cccccc;

  img {
    max-width: 110px;
    margin: auto;
    margin-bottom: 20px;
  }

}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.dropdown-menu {
  li a {
    padding: 10px 14px;
  }
}

.well.pin {
  border: 1px solid #ee4645;
  color: #ee4645;
  font-size: 28px;
  padding: 10px;
  font-weight: bold;
  margin: 0;
}

.fit-column {
  width: 1%;
  white-space: nowrap;
}

.pdf- {
  &my-bar {
    border: 1px solid red;
    display: block;
    width: 200px;
    height: 10px;
    display: block;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: white;
  &.panel-title,&.modal-title  {
    color: $background-color
  }
}


.side-icon {
  margin-bottom: 20px;
}